<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("VIDEO") }}
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            :loading="loading"
            :disabled="loading"
            label="Youtube Video ID"
            v-model="item.ytid"
            type="text"
            prefix="/watch?v="
          ></v-text-field>
          <question-explanation-video
            v-if="item.ytid && item.ytid.length == 11"
            :videoId="item.ytid"
            class="mt-4"
          />
        </v-card-text>
        <v-divider></v-divider>
        <common-create-fields :item="item" variant="default" />

        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn text @click="$router.push({ name: 'dashboard-videos' })">{{
              $lang("CANCEL")
            }}</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="item.id" color="red" outlined @click="handleDelete()">
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import CommonCreateFields from "@/app/dashboard/prep/modules/CommonCreateFields.vue";
import { axios } from "@/plugins/axios";
import QuestionExplanationVideo from "@/components/QuestionExplanationVideo";
import {
  youTubeIdFromLink,
  validateURL,
  validateYouTubeUrl,
} from "@/helpers/utils";

export default {
  name: "dashboard-videos-create",
  components: {
    LayoutCenter,

    CommonCreateFields,
    QuestionExplanationVideo,
  },
  data() {
    return {
      item: {
        id: null,
        title: "",
        description: "",
        language: null,
        ytid: null,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    handleDelete() {
      if (
        !confirm(
          `Are you sure to delete ${
            this.item.title ? this.item.title : this.item.id
          }?`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      this.loding = true;
      return axios
        .delete(`/videos/${this.item.id}`)
        .then((res) => {
          this.$router.replace({ name: "dashboard-videos" });
          return Promise.resolve(res);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchItem(id) {
      return axios
        .get(`videos/${id}`)
        .then((res) => {
          this.item = res.data;
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-videos" });
        });
    },
    doSubmit() {
      if (!this.item.ytid || this.item.ytid.trim().length < 11) {
        alert("youtube video id is required.");
        return;
      }
      if (this.$route.params.id) {
        return axios
          .post(`videos/${this.$route.params.id}`, this.item)
          .then((res) => {
            // this.item = res.data;
            // this.$router.replace({
            //   name: "dashboard-videos-create",
            //   params: { id: this.$route.params.id },
            // });
            this.$router.replace({ name: "dashboard-videos" });
          });
      } else {
        return axios
          .post(`videos`, this.item)
          .then((res) => {
            this.$router.replace({
              name: "dashboard-videos-create",
              params: { id: res.data.data.id },
            });
          })
          .catch(() => {
            // this.$router.replace({ name: "dashboard-videos" });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
    },
    "item.ytid"(val) {
      if (validateYouTubeUrl(val)) {
        this.loading = true;
        let id = youTubeIdFromLink(val);
        console.log("youTubeIdFromLink ID", id);
        setTimeout(() => {
          this.item.ytid = id;
          this.loading = false;
        }, 600);
      }
      this.item.ytid = val;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>
